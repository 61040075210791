import { useState, useEffect, useRef } from 'react';
import cn from 'classnames';

import { generateDayProps, resetHours, handleDateReset, handleSelectChange, renderCurrency } from '@/scenes/objectPage/utils';
import { isDirectBookingForBrandEnabled } from '@/lib/helpers/importBrands';

import { EventTypes, type ObjectData, type UnleashToggles, type AvailabilityRules, type Filters, type AvailabilityPerDate } from '@/domain';
import { useModalFocusTrap } from './useModalFocusTrap';

import { formatBookingDate, formatDateTime } from '@/utils/formatting';
import type { CalendarEvent } from '@/components/calendar/utils/dates';

import { StickyCalendar } from './StickyCalendar';
import { renderImportAdButton } from '../../components/importAdButton/ImportAdButton';
import { CalendarContactFormNotTjvr } from '../calendar/CalendarContactFormNotTjvr';
import { CloseButton } from '@/components/closeButton/CloseButton';
import { AvailabilityForImport } from '../../components/availability/AvailabilityForImport';

import { CalendarSelectGuests } from '../calendar/CalendarSelectGuests';
import { PriceOverview } from '../priceOverview/PriceOverview';
import { NextButton } from './NextButton';

import { Button } from '@fabric-ds/react';

import styles from './FloatingPanel.module.css';

export interface FloatingPanelProps {
    availabilityForPrivate?: AvailabilityRules;
    availabilityPerDate?: AvailabilityPerDate[];
    initialSelectedDates: { from: Date | null; to: Date | null };
    isLoggedIn: boolean;
    norwegianAddress: boolean;
    objectData: ObjectData;
    today?: Date;
    unleash: Partial<UnleashToggles>;
    isPreview: boolean;
    filters: Filters;
}

export const FloatingPanel = ({
    availabilityForPrivate,
    availabilityPerDate,
    initialSelectedDates,
    isLoggedIn,
    norwegianAddress,
    objectData,
    today = new Date(),
    unleash,
    isPreview,
    filters,
}: FloatingPanelProps) => {
    const [numberOfGuests, setNumberOfGuests] = useState('Velg antall');
    const [totalPrice, setTotalPrice] = useState('');
    const { adId, bookings, isAdmin, isImport, owner, organisation, bookingEnabled } = objectData;
    const [open, setOpen] = useState(false);
    const toggleModal = () => setOpen(!open);
    const { modalRef } = useModalFocusTrap(open);
    const openButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (!open) {
            openButtonRef.current?.focus();
        }
    }, [open]);

    resetHours(today);
    resetHours(initialSelectedDates?.from);
    resetHours(initialSelectedDates?.to);

    const initialStartDate = filters?.booking_from ? new Date(filters.booking_from) : (initialSelectedDates?.from ?? null);
    const initialEndDate = filters?.booking_to ? new Date(filters.booking_to) : (initialSelectedDates?.to ?? null);
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(initialStartDate);
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(initialEndDate);
    const selectedDates = { from: selectedStartDate, to: selectedEndDate };

    const calendarEvents: CalendarEvent[] =
        bookings?.map((booking) => ({
            id: String(booking.bookingId),
            type: EventTypes.BOOKING,
            startDate: new Date(booking.startDate),
            endDate: new Date(booking.endDate),
        })) ?? [];

    const handleSelectChangeWithArgs = handleSelectChange(isPreview, setSelectedStartDate, setSelectedEndDate);
    const handleDateResetWithArgs = handleDateReset(setSelectedStartDate, setSelectedEndDate);

    const noGuestsSelected = numberOfGuests === 'Velg antall';
    const hasDates = selectedStartDate !== null && selectedEndDate !== null;
    const showPrice = isLoggedIn && hasDates && !noGuestsSelected;
    const showSelectGuests = isLoggedIn && hasDates;
    const nextButtonProps = { adId, selectedDates, numberOfGuests, isPreview, open };
    const displayDate = (date: Date) => <time dateTime={formatDateTime(date)}>{formatBookingDate(date)}</time>;

    const renderFloatingPanelContent = () => {
        if (!open && (!hasDates || !totalPrice || noGuestsSelected)) {
            return (
                <>
                    <h2 id="modalTitle" className="text-12 font-normal m-0">
                        Velg dato for priser
                    </h2>
                    <Button small primary onClick={toggleModal} ref={openButtonRef} className={styles['toggle-modal-button']}>
                        Velg dato
                    </Button>
                </>
            );
        }

        if (open && isDirectBookingForBrandEnabled(String(organisation?.importId), unleash)) {
            return (
                <AvailabilityForImport
                    adId={adId}
                    noAvailableDates={availabilityPerDate?.length === 0}
                    startDate={selectedStartDate}
                    endDate={selectedEndDate}
                />
            );
        }

        if ((open && isImport) || (open && isAdmin && owner?.deeplink)) {
            return renderImportAdButton({ owner, objectData, startDate: selectedStartDate, endDate: selectedEndDate });
        }

        if (open && !norwegianAddress) {
            return (
                <CalendarContactFormNotTjvr
                    closeForm={handleDateResetWithArgs}
                    endDate={selectedEndDate}
                    isLoggedIn={isLoggedIn}
                    startDate={selectedStartDate}
                />
            );
        }

        if (!isLoggedIn) {
            return (
                <Button primary href="/auth/login" className="w-full max-w-full">
                    Logg inn for å gå videre
                </Button>
            );
        }

        if (!open && hasDates && totalPrice && !noGuestsSelected) {
            return (
                <>
                    <div className={styles['selected-dates-view']}>
                        <h2 id="modalTitle" className="sr-only">
                            Valgte datoer og totalpris
                        </h2>
                        <p className={styles.price}>
                            Totalpris: <strong className={styles.price__number}>{renderCurrency(totalPrice)}</strong>
                        </p>
                        <button className={styles['date-button']} type="button" onClick={toggleModal} aria-label="redigere valgt dato">
                            {displayDate(selectedStartDate)}
                            <span> - </span>
                            {displayDate(selectedEndDate)}
                        </button>
                    </div>
                    <NextButton {...nextButtonProps} />
                </>
            );
        }

        return (
            <>
                {showSelectGuests && (
                    <CalendarSelectGuests
                        numberOfBeds={objectData.noOfBeds}
                        onChange={(event) => setNumberOfGuests(event.target.value)}
                        numberOfGuests={numberOfGuests}
                    />
                )}
                {showPrice && (
                    <PriceOverview
                        adId={adId}
                        location={objectData.location}
                        startDate={selectedStartDate}
                        endDate={selectedEndDate}
                        numberOfGuests={Number(numberOfGuests)}
                        setPrice={setTotalPrice}
                    />
                )}
                <NextButton {...nextButtonProps} />
            </>
        );
    };

    if (!adId || !bookingEnabled) return null;

    return (
        <>
            {open && <div className={styles.overlay} aria-hidden="true" onClick={toggleModal} />}
            <div
                ref={modalRef}
                className={cn(styles['floating-panel'], open ? styles['floating-panel--open'] : styles['floating-panel--closed'])}
                tabIndex={-1}
                // biome-ignore lint/a11y/useSemanticElements: use <dialog> instead (?)
                role="dialog"
                aria-modal="true"
                aria-labelledby="modalTitle"
                data-testid="objectPage-floatingPanel">
                {open && (
                    <>
                        <div className={styles['close-button-container']}>
                            <CloseButton onClick={toggleModal} setOpen={setOpen} className={styles['close-button']} />
                        </div>
                        <div>
                            <StickyCalendar
                                availabilityInfo={availabilityForPrivate}
                                availabilityPerDate={availabilityPerDate}
                                calendarDayPropMiddleware={generateDayProps}
                                events={calendarEvents}
                                onReset={handleDateResetWithArgs}
                                onSelectChange={handleSelectChangeWithArgs}
                                selectedDates={selectedDates}
                                today={today}
                            />
                        </div>
                    </>
                )}
                {renderFloatingPanelContent()}
            </div>
        </>
    );
};
