import getConfig from 'next/config';

import { ProfileButton } from '../profileButton/ProfileButton';
import { ProfileCompany } from '../profileCompany/ProfileCompany';
import { ProfilePublic } from '../profilePublic/ProfilePublic';
import type { ObjectOrganization, ObjectOwner, ProfileIdentity } from '@/domain';
import type { UnleashToggles } from '@/domain';
import type { HostBadgesData } from '@/hooks/useHostBadges';

const {
    publicRuntimeConfig: { contextPath },
} = getConfig();

export type ProfileProps = {
    adId: number;
    bookingEnabled: boolean;
    hostBadges: HostBadgesData;
    isAdmin: boolean;
    isImport: boolean;
    isPreview: boolean;
    isPrivate: boolean;
    moreAdsQuery: string | null;
    onHomepageClick: () => void;
    onMoreAdsClick: () => void;
    onButtonClick: () => void;
    organisation?: ObjectOrganization;
    owner: ObjectOwner;
    profileIdentity?: ProfileIdentity;
    unleash: UnleashToggles;
};

export const Profile = ({
    adId,
    bookingEnabled,
    hostBadges,
    isAdmin,
    isImport,
    isPreview,
    isPrivate,
    moreAdsQuery,
    onHomepageClick,
    onMoreAdsClick,
    onButtonClick,
    organisation,
    owner,
    profileIdentity,
    unleash,
}: ProfileProps) => {
    const badges = unleash?.showHostBadge ? hostBadges : {};

    if (!owner) {
        return null;
    }

    // If import/admin ads don't have any data they can use, don't show the profile at all
    if ((isAdmin || isImport) && !owner.mobile && !owner.phone && !organisation) {
        return null;
    }

    return (
        <div className="p-16 border rounded-8" data-testid="objectPage-profile">
            <div data-testid="adProfile" className="text-center">
                {(isAdmin || isImport) && (
                    <ProfileCompany
                        ownerName={owner.name}
                        ownerPhone={owner.phone || undefined}
                        ownerMobile={owner.mobile || undefined}
                        organisation={organisation}
                    />
                )}

                {isPrivate && <ProfilePublic identity={profileIdentity} badges={badges} />}
                <ProfileButton
                    isAdmin={isAdmin}
                    owner={owner}
                    bookingEnabled={bookingEnabled}
                    isPreview={isPreview}
                    onClick={onButtonClick}
                />
                {!isImport && owner.url && (
                    <a
                        href={`${contextPath}/ut?finnkode=${adId}&deepLinkParams=`}
                        data-testid="webpage"
                        className="block"
                        target="_blank"
                        rel="noopener noreferrer nofollow external"
                        onClick={() => onHomepageClick()}>
                        Hjemmeside
                    </a>
                )}
                {moreAdsQuery && !unleash?.showHostBadge && (
                    <a
                        data-testid="more-ads"
                        className="block"
                        href={`${contextPath}/resultat/?${moreAdsQuery}`}
                        onClick={() => onMoreAdsClick()}>
                        Andre annonser fra samme utleier
                    </a>
                )}
            </div>
        </div>
    );
};
