import { useEffect, useState } from 'react';
import getConfig from 'next/config';
import { Alert, Button } from '@fabric-ds/react';

import { thousandSeparated } from 'shared/util/formatting';
import { formatISODate, formatReadableDateShortNoWeekday } from 'shared/util/datetime-fns';
import { useAvailabilityForImport } from './useAvailabilityForImport';
import { SpinnerIcon } from 'shared/icons/SpinnerIcon';
import type { AlertProps } from 'alert/src/props';

const {
    publicRuntimeConfig: { contextPath },
} = getConfig();

export type Props = {
    adId: number;
    noAvailableDates: boolean;
    startDate: Date | null;
    endDate: Date | null;
};

/**
 * Shows booking availability info/warning alert, price info for selected period, and the direct booking button.
 */
export const AvailabilityForImport = ({ adId, noAvailableDates, startDate, endDate }: Props) => {
    const [showButtonWarning, setShowButtonWarning] = useState(false);
    const { availability, price, loading } = useAvailabilityForImport(adId, startDate, endDate);

    useEffect(() => {
        if (startDate && endDate) {
            setShowButtonWarning(false);
        }
    }, [startDate, endDate]);

    const handleClick = () => {
        if (!startDate || !endDate) {
            setShowButtonWarning(true);
        }
    };

    const renderAlert = () => {
        let alert: { message?: string; type: AlertProps['type'] } = { type: 'negative' };
        if (availability?.error) alert.message = 'Oisann, det ser ut som systemet vårt er nede. Prøv igjen senere.';
        else if (availability?.isCheckinAllowed === false) alert.message = 'Ingen innsjekk denne dagen.';
        else if (availability?.isCheckoutAllowed === false) alert.message = 'Ingen utsjekk denne dagen.';
        else if (availability?.isLessThanMinStay) alert.message = `Minimum opphold ${availability.minStay} netter.`;
        else if (availability?.isMoreThanMaxStay) alert.message = `Maximum opphold ${availability.maxStay} netter.`;
        else if (availability?.isAvailable === false || price?.available === false)
            alert.message = 'Denne perioden er dessverre ikke tilgjengelig for leie. Prøv en annen dato.';
        else if (startDate && !endDate && availability?.minStay)
            alert = {
                message: `Minimum opphold ${availability.minStay} netter.`,
                type: 'info',
            };

        return alert.message ? (
            <Alert type={`${alert.type}`} show>
                {alert.message}
            </Alert>
        ) : null;
    };

    const renderDirectBookingButton = () => {
        const priceInfo = price?.available ? `${thousandSeparated(price.totalPrice)} ${price.currencySymbol}` : null;
        const directBookingAvailable = priceInfo && startDate && endDate;
        const href = directBookingAvailable
            ? `${contextPath}/bestill/?finnkode=${adId}&fra=${formatISODate(startDate)}&til=${formatISODate(endDate)}`
            : undefined;

        return (
            <>
                {directBookingAvailable ? (
                    <>
                        <div className="font-bold text-left">Pris</div>
                        <div className={`grid grid-cols-${priceInfo ? '3' : '1'} gap-16 my-8 text-left`}>
                            <div className={`${priceInfo ? 'col-span-2' : ''}`}>
                                {price?.available
                                    ? `${formatReadableDateShortNoWeekday(startDate)} - ${formatReadableDateShortNoWeekday(endDate)}`
                                    : ''}
                            </div>
                            {Boolean(priceInfo) && <div className="font-bold text-right">{priceInfo}</div>}
                        </div>
                    </>
                ) : null}
                <Button
                    primary
                    className="w-full max-w-full"
                    href={href}
                    onClick={handleClick}
                    disabled={noAvailableDates}
                    data-testid="importAdButton">
                    Gå til bestilling
                </Button>
                {noAvailableDates && (
                    <div className="text-14 text-red-600 mt-8 text-center">Denne annonsen har dessverre ingen tilgjengelige datoer.</div>
                )}
                {showButtonWarning ? (
                    <div className="text-14 text-red-600 mt-8 text-center">Du må velge en leieperiode for å bestille</div>
                ) : null}
            </>
        );
    };

    return (
        <>
            {loading && <SpinnerIcon className="mt-16 mx-auto" />}
            {renderAlert()}
            {renderDirectBookingButton()}
        </>
    );
};
