import type { ObjectOwner } from '@/domain';
import { replacePropsWithPreview } from '../../utils';

export type ProfileButtonProps = {
    isAdmin: boolean;
    bookingEnabled: boolean;
    owner: ObjectOwner;
    isPreview: boolean;
    onClick: () => void;
};

export const ProfileButton = ({ bookingEnabled, isAdmin, owner, isPreview, onClick }: ProfileButtonProps) => {
    // Should never show profile button for private/import ads, but show for admin if calendar is disabled
    if (!isAdmin || bookingEnabled) {
        return null;
    }

    return (
        <div className="my-16" data-testid="profileButton">
            <a
                className="button button--primary w-full max-w-full"
                {...replacePropsWithPreview(
                    {
                        href: owner.deeplink ? owner.deeplink : (owner.url ?? undefined),
                        onClick,
                    },
                    isPreview,
                )}
                target="_blank"
                rel="noopener noreferrer"
                data-testid="seeAvailability">
                Se tilgjengelighet
            </a>
        </div>
    );
};
