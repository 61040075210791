import getConfig from 'next/config';

import useFetch from 'shared/hooks/useFetch';
import { handleFailure } from '@/api/apiHelpers';
import type { AvailabilityRules, Day, NorwegianDay } from '@/domain';

const {
    publicRuntimeConfig: { apiExternalUrl },
    serverRuntimeConfig: { apiInternalUrl },
} = getConfig();

/**
 * Creates a fetch key for a given ad ID.
 *
 * This function generates a unique fetch key based on the ad ID, which
 * is used to cache the data in SWR.
 * The SWR key can't contain `/` so use `_` instead.
 *
 * @param adId - The advertisement ID.
 * @returns The fetch key.
 */
export const createFetchKey = (adId: AvailabilityRules['adId']) => `AVAILABILITY_INFO_${adId}`;

/**
 * Creates a URL for fetching data based on the adId, otherwise returns a key used for caching.
 *
 * This function generates a unique URL, or key, for fetching data based on the ad ID, which
 * is used to cache the data in SWR. Only exported for testing purposes.
 *
 * @param params - The parameters containing adId and an optional isImmutable flag.
 * @returns The URL for fetching data.
 */
export const createUrlOrKey = ({ adId, isImmutable }: { adId: AvailabilityRules['adId']; isImmutable?: boolean }) => {
    // Default isImmutable to true by explicitly checking for `false`
    if (isImmutable === false) {
        const path = `/price/rental/${adId}`;
        return `${apiExternalUrl ?? ''}${path}`;
    }

    return createFetchKey(adId);
};

export const createDefaultState = ({
    adId,
    minRentalDuration,
    maxRentalDuration,
    availabilityList,
    advanceBookingLimit,
}: Partial<AvailabilityRules> & { adId: AvailabilityRules['adId'] }): AvailabilityRules => ({
    adId,
    minRentalDuration: minRentalDuration ?? 1,
    maxRentalDuration: maxRentalDuration ?? null,
    availabilityList: availabilityList ?? [
        { day: 'monday', checkinDay: true },
        { day: 'tuesday', checkinDay: true },
        { day: 'wednesday', checkinDay: true },
        { day: 'thursday', checkinDay: true },
        { day: 'friday', checkinDay: true },
        { day: 'saturday', checkinDay: true },
        { day: 'sunday', checkinDay: true },
    ],
    advanceBookingLimit: advanceBookingLimit ?? 24,
    cancellationPolicy: 'MODERATE',
});

export const fetchAvailabilityForPrivateForServer = async ({
    adId,
}: {
    adId: number;
    isImmutable?: boolean;
}): Promise<AvailabilityRules | null> => {
    const path = `/price/rental/${adId}`;
    const url = `${apiInternalUrl}/travel-api/fhh${path}`;

    return fetch(url)
        .then((response) => handleFailure(response))
        .then((response) =>
            response
                ? createDefaultState({
                      ...response,
                  })
                : null,
        );
};

export const getNorwegianDay = (englishDay: Day): NorwegianDay => {
    switch (englishDay) {
        case 'monday':
            return 'Mandag';
        case 'tuesday':
            return 'Tirsdag';
        case 'wednesday':
            return 'Onsdag';
        case 'thursday':
            return 'Torsdag';
        case 'friday':
            return 'Fredag';
        case 'saturday':
            return 'Lørdag';
        case 'sunday':
            return 'Søndag';
    }
};

export const useAvailabilityForPrivate = ({ adId, isImmutable = true }: { adId: number; isImmutable?: boolean }) => {
    const defaultState: AvailabilityRules = createDefaultState({ adId });
    const shouldHookBeImmutable = isImmutable !== false; // Default isImmutable to true
    const urlOrKey = createUrlOrKey({ adId, isImmutable: shouldHookBeImmutable });

    const { data, error } = useFetch<AvailabilityRules>({
        url: urlOrKey,
        isImmutable: shouldHookBeImmutable,
        statusCodeHandlers: {
            404: () => defaultState,
        },
    });

    return { data, error };
};
