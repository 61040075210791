import { useState } from 'react';

import { useObject } from '@/hooks/useObject';
import { replacePropsWithPreview } from '@/scenes/objectPage/utils';
import { usePreview } from '@/hooks/usePreview';

import { CalendarSelectGuests } from './CalendarSelectGuests';
import { PriceOverview } from '../priceOverview/PriceOverview';

import getConfig from 'next/config';
import { queryString } from 'shared/util/query';
import { safeFormatISODate } from 'shared/util/datetime-fns';

const {
    publicRuntimeConfig: { contextPath },
} = getConfig();

export type CalendarContactFormProps = {
    startDate: Date | null;
    endDate: Date | null;
    isLoggedIn: boolean;
};

export const CalendarContactForm = ({ startDate, endDate, isLoggedIn }: CalendarContactFormProps) => {
    const isPreview = usePreview();
    const objectData = useObject();
    const { adId } = objectData;

    const [numberOfGuests, setNumberOfGuests] = useState('Velg antall');
    const [missingDatesError, setMissingDatesError] = useState(false);
    const [missingGuestsError, setMissingGuestsError] = useState(false);
    const [isInProgress, setInProgress] = useState(false);

    const hasDates = startDate != null && endDate != null;
    const showSelectGuests = isLoggedIn && hasDates;
    const showPrice = isLoggedIn && hasDates && numberOfGuests !== 'Velg antall';

    const renderErrors = () => {
        let errorText = '';
        if (!hasDates && missingDatesError) {
            errorText = 'Velg datoene du ønsker å leie før du går videre';
        } else if (hasDates && numberOfGuests === 'Velg antall' && missingGuestsError) {
            errorText = 'Velg antall gjester før du går videre';
        }
        return <div className="text-12 text-center text-red-600">{errorText}</div>;
    };

    return (
        <>
            {showSelectGuests && (
                <CalendarSelectGuests
                    numberOfBeds={objectData.noOfBeds}
                    onChange={(event) => setNumberOfGuests(event.target.value)}
                    numberOfGuests={numberOfGuests}
                />
            )}
            {showPrice && (
                <PriceOverview
                    adId={adId}
                    location={objectData.location}
                    startDate={startDate}
                    endDate={endDate}
                    numberOfGuests={Number(numberOfGuests)}
                />
            )}
            {!isLoggedIn ? (
                <a className="button button--primary my-16 w-full max-w-full" data-testid="loginLink" href="/auth/login">
                    Logg inn for å gå videre
                </a>
            ) : (
                <a
                    className={`button button--primary my-16 w-full max-w-full ${isInProgress ? 'in-progress' : ''}`}
                    href={`${contextPath}/opprettforesporsel/${queryString({
                        finnkode: adId,
                        startDate: safeFormatISODate(startDate),
                        endDate: safeFormatISODate(endDate),
                        guests: numberOfGuests,
                    })}`}
                    {...replacePropsWithPreview(
                        {
                            onClick: (e) => {
                                if (!hasDates) {
                                    e.preventDefault();
                                    setMissingDatesError(true);
                                } else if (numberOfGuests === 'Velg antall') {
                                    e.preventDefault();
                                    setMissingGuestsError(true);
                                } else {
                                    setInProgress(true);
                                    // Workaround for sticky progress bar when command-clicking the link:
                                    setTimeout(() => {
                                        setInProgress(false);
                                    }, 3000);
                                }
                            },
                        },
                        isPreview,
                    )}>
                    Gå videre
                </a>
            )}
            {renderErrors()}
            {showPrice && <div className="text-12 text-center text-gray-500">Du betaler ingenting ennå</div>}
        </>
    );
};
